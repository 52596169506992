@import "./variables";

.elliemae {
  .backArrow {
    svg {
        fill: #0168ad;
        filter: grayscale(1) brightness(1.2);
        transition: filter 0.25s;
    }
    svg:hover {
      filter: grayscale(0) brightness(1);
    }
  }
  .innerWrapper {
      padding: 75px 0;
      display: flex;
      flex-direction: column;
      margin: 0;

      .section {
        margin: 0;
        box-sizing: border-box;
      }

      .section:nth-child(2n) {
        background-color: #f8f8f8;
      }

      .sectionContent {
        padding: 40px 170px 40px 170px;
        margin: auto;
        max-width: 900px;
      }

      h2 {
        line-height: 1.5;
        margin: 20px 0 5px 0;
        color: #f37727;
        font-weight: 500;
        font-size: 29px;
      }
      h3 {
        line-height: 2;
        margin: 20px 0 0 0;
      }
      p {
        line-height: 26px;;
        margin: 5px 0 0 0;
      }
      ol {
        margin: 5px 0 0 0;
        padding-left: 17px;
      }
      li {
        line-height: 1.5;
      }
      object {
        margin-top: 20px;
      }

      > img {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 30px;
      }

      #header {
        display: flex;
        padding: 24px 0 52px 0;
        img {
          width: initial;
          height: 64px;
          margin-top: 2px;
        }
        #headerDetails {
          display: flex;
          flex-direction: column;
          h5 {
            line-height: 2;
            margin: 0 0 0 32px;
            color: #2a2a2a;
            border-left: 2px solid #0168ad;
            padding: 8px 0 8px 32px;
          }
        }
      }

      #navbarImg {
        background-color: #f3f3f3;
        padding: 5px;
        margin: 20px 0;
        img {
          width: 100%;
        }
      }
  }
  @media screen and (max-width: $breakSmall) {
    #contact-info h1 {
      font-size: 24px;
    }
    #contact-info h2 {
      font-size: 14px;
    }

    .innerWrapper #header {
      display: block;
      width: 100%;
      height: auto !important;
      margin-bottom: 50px;
      img {
        width: 100%;
        margin: 0;
        height: auto;
      }
    }
    .innerWrapper #header #headerDetails h5 {
      padding: 10px 0 17px 0;
      border-left: none;
      border-bottom: 2px solid #0070af;
      margin: 10px 0;
    }
    .innerWrapper {
      padding: 0 0 75px 0;
    }
  }
}
