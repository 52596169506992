@import "./variables";

#App {
   width: 100vw;
   height: 100vh;
   overflow: hidden;
}

.route-section {
   animation-duration: 0.6s;
   animation-fill-mode: both;
}

#wrapper {
   left: $navbarWidth;
   width: calc(100% - #{$navbarWidth});
   height: 100%;
   overflow-y: overlay;
   overflow-x: hidden; 
   position: fixed;
   top: 0;
}

#wrapper.smallNav {
   left: $navbarWidthSmall;
   width: calc(100% - #{$navbarWidthSmall});
}

.topBanner {
   height: 388px;
   background-repeat: no-repeat;
   background-position: 50% 50%;

   .backArrow {
      svg {
         filter: brightness(1);
         transition: filter 0.25s;
      }
      svg:hover {
         filter: brightness(1.3);
      }
   }
}


//Gallery
.horizGallery {
   display: flex;
   width: 100%;
   overflow-x: auto;
   > div {
      padding: 20px 120px 20px 0;
      display: flex;
      img {
         height: 486px;
         box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
         margin-right: 35px;
         cursor: pointer;
      }
      img:nth-child(1) {
         margin-left: 35px;
      }
   }
}
.horizGallery::-webkit-scrollbar {
  height: 10px;
  display: none;
}
.fullScreen::-webkit-scrollbar {
  display: block;
}
.horizGallery::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.88);
}
.horizGallery::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

@media screen and (max-width: $breakSmall)  {
  #wrapper {
     top: $navbarMobileHeight;
     width: 100%;
     left: 0;
     height: calc(100% - #{$navbarMobileHeight});
  }

  #wrapper.smallNav {
     left: 0;
     width: 100%;
  }

  .horizGallery {
     > div {
        img {
           height: 300px;
           margin-right: 20px;
        }
        img:nth-child(1) {
           margin-left: 20px;
        }
     }
  }
}

.fade-enter {
   opacity: 0;
   animation-name: fadeIn;
}

.fade-enter.fade-enter-active {
   opacity: 1;
   animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
