$cardWidth: 276px;
$cardGap: 36px;
$portfolioPadding: 100px;

$navbarWidth: 446px;
$navbarWidthSmall: 146px;
$navbarMobileHeight: 80px;

$breakLarge: 3*$cardWidth + 2*$cardGap + $navbarWidth + 2*$portfolioPadding;
$breakMedium: 2*$cardWidth + 1*$cardGap + $navbarWidth + 2*$portfolioPadding;
$breakSmall: 1*$cardWidth + 0*$cardGap + $navbarWidth + 2*$portfolioPadding;

$headerFontFamily: 'Google Sans', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
$bodyFontFamily: 'Inter UI', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
