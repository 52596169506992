@import "./variables";

.squid {
  overflow-x: hidden;

  .innerWrapper {
      h1 {
        color: #0C758A;
        font-weight: 500;
      }
      h2 {
        color: #0C758A;
      }
      h3 {
        color: #0C758A;
      }
      b {
        font-weight: 500;
      }
      ol {
        margin: 5px 0 0 0;
        padding-left: 17px;
      }
      li {
        line-height: 1.5;
      }

      .caption {
         color: #525252;
         font-size: 14px;
         text-align: center;
         padding: 12px 0;
      }

      #intro {
         .sectionContent {
            padding: 80px 150px 108px 150px;
         }
         #summary {
           .col {
             margin-right: 64px;
             flex-basis: 853px;
             padding-bottom: 12px;
             p {
               padding: 0;
               line-height: 1.5;
             }
             h5 {
               padding: 10px 0 0px 0;
             }
           }
           p {
             padding: 10px 0 0 0;
             line-height: 2;
           }
         }
      }

      #finalpreview {
         background-color: #f6f6f6;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 32px 32px 8px 32px;
         video {
            padding: 0;
            max-width: 820px;
            width: 100%;
         }
      }

      #research {
         h2 {
            padding: 6px 0 60px 0;
            line-height: 1;
         }
         h3 {
            padding-bottom: 0;
            font-weight: 400;
         }

         .fancyNumber {
            font-size: 80px;
            font-weight: 600;
            border-bottom: 7px solid;
            line-height: 1;
            padding: 8px 0;
            margin-right: 100px;
            max-width: 103px;
         }

         #gatheringInfo {
            #infoQuote {
               color: #0091AD;
               line-height: 1.333;
               padding: 32px 0 60px 0;
            }
         }

         #usertestingPic {
            margin-top: 32px;
            .row {
               position: relative;
               flex-grow: 1;
               img {
                  width: 100%;
                  max-width: 630px;
                  max-height: 100%;
                  border: 1px solid #F3F3F3;
                  border-radius: 5px;
               }
               .col {
                  padding: 20px 30px 16px 24px;
                  background-color: #fff;
                  border: 1px solid #eaeaea;
                  border-radius: 5px;
                  position: absolute;
                  right: -20px;
                  top: 50%;
                  transform: translate(0%, -50%);
                  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                  h4 {
                     padding-top: 8px;
                     line-height: 1;
                     font-weight: 500;
                  }
                  h3 {
                     font-weight: 600;
                     padding-top: 16px;
                     color: #000;
                  }
               }
            }
         }

         #why {
            margin: 60px 0 68px 0;
            .col {
               flex-grow: 1;
               h3 {
                  color: #000;
                  font-weight: 600;
                  padding-top: 80px;
               }
               h4 {
                  color: #000;
               }
            }
            img {
               max-height: 526px;
               max-width: 100%;
               padding-left: 68px;
               margin: auto;
            }
         }
      }

      #problem {
         background-color: #02A0BF;
         padding: 80px;
         text-align: center;
         box-shadow: #56c1d6 0 0 52px 0px;
         h3 {
            color: #E4FBFF;
            line-height: 1.5;
         }
      }

      #designsTitle {
         display: flex;
         justify-content: center;
         align-items: center;
         h1 {
            padding: 136px 0 0 0;
         }
         #littleUnderline {
            height: 6px;
            width: 84px;
            background-color: #0C758A;
         }
      }

      #designs {
         text-align: center;
         .section:nth-child(even) {
            background-color: #f6f6f6;
         }
         .sectionContent {
            padding: 128px;
            max-width: 1080px;
            display: flex;
            justify-content: center;

            img {
               width: 100%;
            }
         }


         #toolbox {
            img {
               max-width: 922px;
            }
         }
         #toolboxExtras {
            .imageWrapper:nth-child(1) {
               padding: 0 100px 0 0;
            }
            img {
               max-width: 353px;
            }
         }
         #penDetail {
            img {
               max-width: 1016px;
            }
         }
         #toolbars {
            img {
               max-width: 802px;
            }
         }
         #menus {
            img {
               max-width: 533px;
            }
         }
         #pageSwitcher {
            .sectionContent {
               padding-bottom: 0;
            }
            img {
               flex-basis: 864px;
            }
         }
      }

      #conclusion {
         .sectionContent {
            text-align: center;
            padding: 100px 150px 120px 150px;
         }
      }
   }
}

@media screen and (max-width: $breakMedium) {
   .squid {
      .innerWrapper {
         #designs {
            .twoImage {
               padding: 80px;
            }
            #toolboxExtras {
              .imageWrapper:nth-child(1) {
                 padding: 0 32px 0 0;
              }
            }
         }
      }
   }
}

@media screen and (max-width: $breakSmall) {
   .squid {
      .innerWrapper {
         #research {
            #why {
               img {
                  padding: 24px 0 0 0;
               }
            }
         }

         #designs {
            .section {
               .twoImage {
                  flex-direction: column;
                  img {
                     max-width: 300px;
                  }
               }
            }
            #toolboxExtras {
              .imageWrapper:nth-child(1) {
                 padding: 0;
              }
              .imageWrapper:nth-child(2) {
                 transform: translateY(-32px);
              }
              .sectionContent {
                 padding-bottom: 0 !important;
              }
            }
         }
      }
   }
}
