@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-MediumItalic.woff2') format('woff2'),
        url('../files/fonts/InterUI-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-Regular.woff2') format('woff2'),
        url('../files/fonts/InterUI-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-Bold.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-Regular.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-Italic.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-Medium.woff2') format('woff2'),
        url('../files/fonts/InterUI-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-Italic.woff2') format('woff2'),
        url('../files/fonts/InterUI-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-BoldItalic.woff2') format('woff2'),
        url('../files/fonts/InterUI-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Inter UI';
    src: url('../files/fonts/InterUI-Bold.woff2') format('woff2'),
        url('../files/fonts/InterUI-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../files/fonts/GoogleSans-Medium.woff2') format('woff2'),
        url('../files/fonts/GoogleSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
