@import "./variables";

.photography {
   text-align: center;
   padding-bottom: 75px;
   background-color: #181818;
   img {
      filter: grayscale(100%);
      transition: filter 0.55s;
      max-width: calc(100% - 150px);
      max-height: calc(100vh - 150px);
      margin: 75px 75px 0 75px;
   }
   .currentPic {
      filter: grayscale(0%) !important;
   }

   @media screen and (max-width: $breakSmall) {
      img {
         margin: 32px 0 0 0;
         max-width: calc(100% - 64px);
         max-height: calc(100vh - 64px);
      }
   }
}
