@import "./variables";

.ebay {
  overflow-x: hidden;
  .innerWrapper {
     h1 {
        color: #0065C2;
     }
     h2 {
        color: #0065C2;
     }
     b {
        font-weight: 500;
     }
     ol {
        margin: 5px 0 0 0;
        padding-left: 17px;
     }
     li {
        line-height: 1.5;
     }
     object {
        margin-top: 20px;
     }

      #ebayFooter {
        padding: 24px 0;
        background-color: #e3edf5;
      }
      #lightbulbBanner {
        background-color: #fffbe9;
        display: flex;
        flex-direction: row;
        padding: 4px 28px 3px 0;
        width: calc(100% + 104px);
        transform: translateX(-76px);
        margin-top: 8px;
        border-radius: 5px;
        img {
          min-width: 32px;
          padding: 0 20px 0 24px;
          transform: translateY(-4px);
        }
        p {
          font-family: $headerFontFamily;
          font-weight: 700;
          color: #956C00;
        }
      }

      #proconChart {
        padding: 28px 32px;
        background-color: #F3FAFF;
        max-width: 380px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        h4 {
          color: #0053A0;
          padding: 0;
          margin-bottom: 8px;
        }
        div {
          display: flex;
          flex-direction: row;
          img {
            margin-left: 16px;
            width: 20px;
          }
          p {
            width: calc(100% - 36px);
            color: #0053A0;
            padding: 6px 0;
          }
        }
      }

      #dlButton {
        display: inline-block;
        color: white;
        background-color: #0d71ff;
        padding: 14px 20px;
        border-radius: 5px;
        line-height: 16px;
        margin: 0 0 32px 0;
        transition: all 0.25s;
        font-weight: 700;
        font-size: 16px;
        font-family: $headerFontFamily;
        box-shadow: 0px 6px 26px #ffffff00;
        border: none;
        img {
          width: 16px;
          border: none;
          margin-left: 12px;
          transform: translateY(1px);
        }
      }
      #dlButton:hover {
        transform: translateY(-6px);
        cursor: pointer;
        box-shadow: 0px 6px 26px #0d71ff75;
      }
      #dlButton:active {
        transform: translateY(0px);
        cursor: pointer;
      }
      #dlButton:focus {
        outline: none;
      }

      #navbarImg {
        background-color: #f3f3f3;
        padding: 5px;
        margin: 20px 0;
        img {
          width: 100%;
        }
      }

      #finalSSF {
        background-color: #F3FAFF;
        #final {
          justify-content: center;
        }
        video {
          max-height: 505px;
          min-width: 99px;
          padding: 0px 40px;
        }
        p {
          color: #0f3652;
        }
        h4 {
          color: #0f3652;
        }
      }
   }
}

@media screen and (max-width: $breakMedium) {
  .ebay {
    .innerWrapper {
      #lightbulbBanner {
        transform: none;
        width: 100%;
        padding: 0;
        img {
          display: none;
        }
        p {
          padding: 16px 24px;
        }
      }
    }
  }
}
@media screen and (max-width: $breakSmall) {
  .ebay {
    .innerWrapper {
      #proconChart {
        padding: 20px 28px;
      }
    }
  }
}
