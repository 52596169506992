@import "./variables";

#contact {
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   height: 100vh;
   #contentWrapper {
      #content {
         padding: 32px;
         display: flex;
         flex-direction: column;
         max-width: 100%;
         > svg {
            max-width: 100%;
         }
         #popup {
            position: absolute;
            z-index: 2;
            border-radius: 4px;
            padding: 4px 0;
            background-color: #fff;
            transform: translateY(-196px);
            box-shadow: 0px 4px 20px #0000001a;
            box-sizing: border-box;
            transition: all 90ms ease-in-out;
            .menuItem {
               padding: 16px 32px;
               align-items: center;
               display: flex;
               flex-direction: row;
               p {
                  line-height: 24px;
                  margin-left: 16px;
                  padding: 0;
               }
            }
            div.menuItem {
               border-top: 1.25px solid #f6f6f6;
               &:hover {
                  cursor: pointer;
                  background-color: #f6f6f6;
               }
               &:active {
                  cursor: pointer;
                  background-color: #f1f1f1;
               }
            }
         }

         #popup.popup-enter {
           opacity: 0;
           transform: translateY(-180px);
         }

         #popup.popup-exit-active {
           opacity: 0;
           transform: translateY(-180px);
         }

         #links {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            > div {
               max-width: calc(25vw - 24px);
               max-height: calc(25vw - 24px);
               > a {
                  > svg {
                     width: 100%;
                     height: 100%;
                     transition: 0.3s all;
                     filter: drop-shadow( 0px 4px 7px rgba(0, 0, 0, 0));
                     &:hover {
                        cursor: pointer;
                        transform: translateY(-4px);
                        filter: drop-shadow( 0px 4px 7px rgba(0, 0, 0, .2));
                     }
                     &:active {
                        transform: translateY(0px);
                        filter: drop-shadow( 0px 0px 7px rgba(0, 0, 0, .2));
                     }
                  }
               }
            }
         }
      }
   }

   #GreenRect {
      position: absolute;
      right: 55px;
      top: -57px;
      max-width: 30vw;
      max-height: 30vw;
   }
   #GreyCirc {
      position: absolute;
      left: 8%;
      top: 8%;
   }
   #OrangeCirc {
      position: absolute;
      bottom: 20%;
      left: 30%;
      width: 24px;
      height: 24px;
   }
   #RedCirc {
      position: absolute;
      left: -73px;
      bottom: -51px;
      max-width: 25vw;
      max-height: 25vw;
   }
   #BlueTri {
      position: absolute;
      right: -44px;
      bottom: 10%;
      max-width: 30vw;
      max-height: 30vw;
   }

   #background {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
   }
}

@media screen and (max-width: $breakSmall)  {
  #contact {
     #contentWrapper {
        #content {
          #links {
             margin-top: 12px;
          }
          #popup {
             padding: 2px 0;
             .menuItem {
               padding: 16px 24px;
               > svg {
                  width: 20px;
                  height: 20px;
               }
            }
          }
       }
     }
  }
}
