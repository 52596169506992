@import "./variables";

#portfolio {
   #portfolioBody {
      display: grid;
      justify-content: center;
      padding: 150px $portfolioPadding 75px $portfolioPadding;
      grid-gap: 8px;

      #header {
         padding: 0;
         width: 100%;
         font-weight: 400;
         letter-spacing: -0.1px;
      }

      #filterBar {
         display: flex;
         margin-bottom: 28px;
         .filter {
            font-family: $headerFontFamily;
            padding: 12px 20px;
            line-height: 1;
            border-radius: 52px;
            margin-left: 12px;
            background-color: #f7f7f7;
            color: #919196;
            transition: 0.3s all;
            font-weight: 600;
            &:nth-child(1) {
               margin-left: 0;
            }
            &:hover {
               cursor: pointer;
               &:nth-child(1) {
                color: #4676fd;
                background-color: #eef3ff;
               }
               &:nth-child(2) {
                color: #1ea96f;
                background-color: #e8f9f0;
               }
               &:nth-child(3) {
                color: #ef8739;
                background-color: #fff3eb;
               }
            }
            &.selected {
               color: #fff;
               &:nth-child(1) {
                background-color: #4676fd;
               }
               &:nth-child(2) {
                background-color: #20b376;
               }
               &:nth-child(3) {
                background-color: #f78f40;
               }
               &:hover {
                color: #fff;
               }
            }
         }
      }

      #cardGrid {
         display: grid;
         grid-template-columns: repeat(3, $cardWidth);
         grid-auto-rows: 324px;
         justify-content: center;
         grid-gap: $cardGap;

         .card {
            display: flex;
            overflow: hidden;
            position: relative;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.14);
            transition: box-shadow 0.3s, transform 0.3s;
            cursor: pointer;
            text-decoration: none;
            .imageWrapper {
               display: flex;
               justify-content: center;
               flex: 1;
               padding: 60px 40px 40px 40px;
               img {
                  max-width: 100px;
                  max-height: 100px;
                  align-self: center;
               }
            }
            .textWrapper {
               display: block;
               background-color: rgba(0,0,0,0.05);
               padding: 14px 24px 16px 24px;
               h4 {
                  padding: 0;
                  color: white;
               }
               p {
                  line-height: 20px;
                  font-size: 14px;
                  color: white;
                  padding: 0;
               }
            }
            .label {
               font-family: $headerFontFamily;
               font-weight: 600;
               letter-spacing: 0.2px;
               position: absolute;
               right: 17px;
               top: 15px;
               background-color: #fff;
               font-size: 12px;
               border-radius: 2px;
               opacity: 0;
               transition: 0.4s all;
               padding: 2px 8px 1px 8px;
               margin: 0;
               z-index: 2;
            }
            .Work {
               color: #4676fd;
            }
            .Project {
               color: #20b376;
            }
            .Other {
               color: #f78f40;
            }
            &.card:hover {
               transform: translateY(-7px);
               box-shadow: 0px 7px 26px 0px rgba(0,0,0,.24);
               .label {
                  opacity: 1;
               }
            }
            &.card:active {
               transform: translateY(-4px);
            }
         }
         #photography {
            background-image: url("../images/photography/kina.jpg");
            background-size: cover;
            background-position-x: 53%;
            .textWrapper {
               background-color: rgba(0,0,0,0.15);
            }
         }
         #ellieMae {
            .imageWrapper {
               img {
                  max-width: 158px;
               }
            }
         }
         #ebay {
            .imageWrapper {
               img {
                  max-width: 158px;
               }
            }
         }
         #squid {
            .imageWrapper {
               img {
                  max-height: 120px;
                  max-width: 120px;
               }
            }
         }
         #jupyter {
            .imageWrapper {
               img {
                  max-height: 120px;
                  width: 120px;
               }
            }
         }
         #fiveMinShower {
            .imageWrapper {
               img {
                  max-height: 128px;
               }
            }
         }
         #dribbble {
            .imageWrapper {
               img {
                  max-height: 120px;
                  max-width: 120px;
               }
            }
         }
      }
   }

   #footer {
      a {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 16px;
         border-top: 2px solid #f8f8f8;
         h4 {
            transition: 0.3s color;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
            padding: 0;
            color: #a5a5a5;
            font-weight: 400;
            white-space: nowrap;
         }
         svg {
            transition: 0.3s fill;
         }
         #reactIcon {
            width: 29px;
            fill: #8ddcf1;
            padding-bottom: 2px;
         }
         #heartIcon {
            width: 15px;
            padding: 0 4px 1px 4px;
            fill: #ef8e90;
         }
      }
      a:hover {
        h4 {
          color: rgb(109, 109, 109);
        }
        #reactIcon {
           fill: #3ccff7;
        }
        #heartIcon {
           fill: #f55f62;
        }
      }
   }
}

@media screen and (max-width: $breakLarge) {
   #portfolio {
      #portfolioBody {
         #cardGrid {
            grid-template-columns: repeat(2, $cardWidth);
         }
      }
   }
}

@media screen and (max-width: $breakMedium) {
   #portfolio {
      #portfolioBody {
         #header {
            width: 276px;
         }
         #cardGrid {
            grid-template-columns: repeat(1, max-content);
         }
      }
   }
}

@media screen and (max-width: $breakSmall) {
   #portfolio {
      #portfolioBody {
         padding: 40px 0;
         grid-gap: 24px;
         #filterBar {
            display: none;
         }
         #header {
            width: calc(100vw - 64px);
         }
         #cardGrid {
            grid-gap: 18px;
            .card {
               width: calc(100vw - 64px);
            }
         }
      }
      #footer {
         a {
            padding: 12px;
            h4 {
               font-size: 13px;
               #heartIcon {
                  width: 13px;
                  fill: #6F73FF;
               }
               #reactIcon {
                  width: 25px;
                  fill: #61DAFB;
               }
            }
         }
      }
   }
}
